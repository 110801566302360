<script setup lang="ts">
export interface IBaseSearchInputProps {
  /**
   * Default to v-model
   */
  modelValue?: string;
  /**
   * Placeholder for the search input.
   */
  placeholder?: string;
  /**
   * List of the most common sport types.
   */
  mostCommonSportTypesList?: TSportType[];
  /**
   * Custom class for the root element.
   */
  rootClass?: string;
}

const { t, locale } = useI18n();
const emit = defineEmits(["update:modelValue", "clear"]);
const props = defineProps<IBaseSearchInputProps>();

const onClickClear = () => emit("clear");

const searchInputRef = ref<HTMLInputElement | null>(null);
const searchValue = useVModel(props, "modelValue", emit);

const isRtl = computed(() => locale.value === "he");
const sportTypesOptions = computed(() => {
  if (!props.mostCommonSportTypesList)
    return [];
  return props.mostCommonSportTypesList.map(sport => ({ title: t(`sport_types.${sport}`), value: sport }));
});
const contentClass = computed(() => {
  let classes = "background-main shadow-main rounded-main flex flex-wrap gap-4 p-4 w-full !w-auto !top-[calc(100%+8px)]";
  if (sportTypesOptions.value.length < 2)
    classes += " hidden";

  return classes;
});

defineExpose({ focus: () => searchInputRef.value?.focus() });
</script>

<template>
  <BasePopover
    :content-class="contentClass"
    :placement=" isRtl ? 'bottom-left' : 'bottom-right'"
  >
    <template #default="{ toggle, close }">
      <BaseInput
        ref="searchInputRef"
        v-model="searchValue"
        :title="props.placeholder"
        name="search"
        :class="props.rootClass"
        search
        autocomplete="off"
        :placeholder="props.placeholder"
        @clear="onClickClear"
        @click="toggle"
        @update:model-value="close"
      />
    </template>

    <template #content="{ close }">
      <div class="w-full text-sm font-medium text-secondary">
        {{ t('labels.search_by_sport_type') }}
      </div>

      <BaseChip v-for="sport in sportTypesOptions" :key="sport.value" class="!px-2 !py-1 !m-0 bg-[var(--base-card-bgSecondaryColor)]">
        <BaseButton
          name="header-search-sport-type-options"
          type="icon"
          tag="nuxt-link"
          :to="{ path: '/search/', query: { sport_type: [sport.value] } }"
          @click.capture="close"
        >
          <div class="flex items-center justify-center gap-2">
            <IconSportType :sport-type="sport.value" class="text-[var(--base-icon-color)]" />
            {{ sport.title }}
          </div>
        </BaseButton>
      </BaseChip>
    </template>
  </BasePopover>
</template>
